var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settingFormComponent"},[_c('BaseForm',{ref:"form",attrs:{"customClass":"settingCustomClass","label-width":"80px","label-position":"left","topPosition":true,"cols":_vm.formField,"form":_vm.form},scopedSlots:_vm._u([{key:"structsSlot",fn:function(ref){
var ref_scoped = ref.scoped;
var prop = ref_scoped.prop;
var placeholder = ref_scoped.placeholder;
return [_c('el-button',{staticClass:"add-struct",attrs:{"size":"mini","icon":"el-icon-plus","type":"primary"},on:{"click":_vm.addNewItem}},[_vm._v(" 添加款式项 ")]),_vm._l((_vm.form[prop]),function(struct,index){return _c('div',{key:index,staticStyle:{"display":"inline-block"}},[_c('BaseForm',{ref:"structForm",refInFor:true,attrs:{"label-width":"180px","customClass":"structCommonClass","topPosition":false,"cols":_vm.structField,"form":struct || {}},scopedSlots:_vm._u([{key:"colorSlot",fn:function(ref){
var prop = ref.scoped.prop;
return [_c('el-color-picker',{model:{value:(struct[prop]),callback:function ($$v) {_vm.$set(struct, prop, $$v)},expression:"struct[prop]"}}),(_vm.form['structs'].length > 1)?_c('el-button',{staticClass:"reduce-item",attrs:{"size":"mini","icon":"el-icon-delete","type":"text"},on:{"click":function($event){return _vm.reduceNewItem(index)}}},[_vm._v(" 刪減款式项 ")]):_vm._e()]}},{key:"figuresSlot",fn:function(ref){
var prop = ref.scoped.prop;
return [_c('ImgUpload',{attrs:{"files":struct[prop],"multiple":"","limit":5},on:{"update:files":function($event){return _vm.$set(struct, prop, $event)}}})]}},{key:"detailSlot",fn:function(ref){
var prop = ref.scoped.prop;
return [_c('Editor',{attrs:{"id":("structEditor_" + index),"val":struct[prop],"placeholder":placeholder,"height":200,"serverUrl":"serverUrl","useObjectStr":"CONTENT_UEDITOR_FILE"},on:{"update:val":function($event){return _vm.$set(struct, prop, $event)}}})]}}],null,true)})],1)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }